import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { Section } from "../components/Section"
import { ThanksIcon } from "../components/Icons"

const Thanks = () => (
  <Layout>
    <SEO title="Thanks" />
    <Section className="section" pt="100px" pb="70px" bg="#fff">
      <Container>
            <div className="section-heading text-center">
              <span className="section-icon"><ThanksIcon width='34px' height='34px' />  </span>
              <h1 className="section-title">Thank you for connecting with us</h1>
              <p>Thank you for your interest in Carports R Us with your metal building needs! We'll certainly get back to you with more details.</p>
            </div>       
      </Container>
    </Section>
  </Layout>
)

export default Thanks
